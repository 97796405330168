<template lang='pug'>
  .newsletter
    particles
      h1.animated.fadeInUp Bizimle İletişime Geçin
      //h3.animated.fadeInUp.wait-p2s Get notified of important announcements and new major releases.
    .newsletter-content
      v-container.pa-0(fluid, grid-list-xl)
        v-layout(row v-if='formNotSubmitted')
          v-flex(xs1, xl2).grey.lighten-4.hidden-sm-and-down
          v-flex(xs12, md10, lg6, xl4).grey.lighten-4
            .newsletter-form
              h2 Ücretsiz Deneme
              .newsletter-form-text İletişim formunu doldurmanız durumunda sizinle iletişime geçilecektir
              //.newsletter-form-text We hate spam just as much as you do!
              .newsletter-form-fields
                  v-text-field(
                  v-model='email'
                  label='Email'
                  )
                  v-text-field(
                  v-model='name'
                  label='İsim Soyisim'
                  )
                  v-text-field(
                  v-model='phone'
                  :items='phone'
                  label='Telefon'
                  )
                  v-text-field(
                  v-model='message'
                  label='Açıklama'
                  )
                  v-select(
                  v-model='sector'
                  :items='sectorList'
                  label='Sektör'
                  append-icon=''
                  )
                  v-text-field(
                  v-model='company'
                  :items='company'
                  label='Firma İsmi'
                  )
                  v-select(
                  v-model='client'
                  :items='clientList'
                  label='Kullanıcı Sayısı'
                  append-icon=''
                  )
                  v-select(
                  v-model='branch'
                  :items='branchList'
                  label='Lokasyon Sayısı'
                  append-icon=''
                  )
              v-alert(:color='alertColor', v-model='alertShown', outline) {{alertMessage}}
              v-btn(large, color='primary', block, @click='subscribe', :loading='isLoading') Gönder
              .newsletter-form-link
                a(href='https://sekuritim.com/', target='_blank') Diğer hizmetlerimiz için sitemizi ziyaret edebilirsiniz
          v-flex(xs12, lg6, xl6).white.hidden-md-and-down
            .newsletter-img
              img.animated.fadeInUp(:src='require("../assets/illustrations/fogg-message-sent.png")')
          v-flex(xs1, lg2).grey.lighten-4.hidden-sm-and-down.hidden-lg-and-up
</template>

<script>
import Particles from '../components/Particles'
// import _ from 'lodash'
// import TASKS_ALL from '../graphql/TasksAll.gql'
// import TASKS_CREATE from '../graphql/TasksCreate.gql'
import submitMessage from '../graphql/submitMessage.gql'
// import gql from 'graphql-tag'
import swal from 'sweetalert'
// import validate from 'validate.js'

export default {
  components: {
    Particles
  },
  data () {
    return {
      formNotSubmitted: true,
      alertShown: false,
      alertColor: 'success',
      alertMessage: '',
      isLoading: false,
      email: '',
      name: '',
      phone: '',
      message: '',
      sector: '',
      company: '',
      client: '',
      branch: '',
      clientList: [
        { value: '100Client', text: '100+ Kullanıcı' },
        { value: '250Client', text: '250+ Kullanıcı' },
        { value: '1000Client', text: '1000+ Kullanıcı' },
        { value: '2000Client', text: '2000+ Kullanıcı' }
      ],
      sectorList: [
        { value: 'Yeme & İçme', text: 'Yeme & İçme' },
        { value: 'Perakende', text: 'Perakende' },
        { value: 'Alışveriş Merkezi', text: 'Alışveriş Merkezi' },
        { value: 'Restoran', text: 'Restoran' },
        { value: 'Otel', text: 'Otel' },
        { value: 'Toplantı Salonu', text: 'Toplantı Salonu' },
        { value: 'Ulaşım', text: 'Ulaşım' },
        { value: 'Diğer', text: 'Diğer' }
      ],
      branchList: [
        { value: '1 Şube', text: '1 Şube' },
        { value: '2 Şube', text: '2 Şube' },
        { value: '3 Şube', text: '3 Şube' },
        { value: '4 Şube', text: '4 Şube' },
        { value: 'Diğer', text: 'Diğer' }
      ]
    }
  },
  methods: {
    subscribe (e) {
      e.preventDefault()
      const validate = require('validate.js')
      const emailConstraint = {
        email: {
          email: true,
          presence: true
        }
      }
      let emailErr = validate({ email: this.email }, emailConstraint)
      if (this.name && this.phone && this.message && typeof emailErr === 'undefined') {
        let mesg = {
          'message': this.message,
          'sector': this.sector,
          'client': this.client,
          'company': this.company,
          'branch': this.branch
        }
        // console.log(mesg)
        this.$apollo.mutate({
          mutation: submitMessage,
          variables: {
            email: this.email,
            name: this.name,
            phone: this.phone,
            message: JSON.stringify(mesg)
            // const JSON.stringify(mesg){
            //     sector: this.sector,
            //     Company: this.Company,
            //     client: this.client
            // }
          }
        })
        this.subscribe = true
        swal('Teşekkürler', 'Sizinle en kısa sürede iletişime geçeceğiz', 'success')
        this.formNotSubmitted = false
      }
      if (!this.name) {
        this.alertColor = 'error'
        this.alertMessage = 'İsim gereklidir'
        this.alertShown = true
        return
      }
      if (typeof emailErr !== 'undefined') {
        this.alertColor = 'error'
        this.alertMessage = 'E-mail gereklidir.'
        this.alertShown = true
        return
      }
      if (!this.phone) {
        this.alertColor = 'error'
        this.alertMessage = 'Telefon numarası gereklidir.'
        this.alertShown = true
        return
      }
      if (!this.message) {
        this.alertColor = 'error'
        this.alertMessage = 'Mesaj bırakmanızı rica ederiz. '
        this.alertShown = true
      }
    },
    mounted () {
      this.alertShown = false
      this.formNotSubmitted = true
      this.subscribe = false
    }
  }
}

</script>

<style lang='scss'>
.newsletter {
  &-content {
    padding: 12px;
  }

  &-form {
    padding: 100px 25px;

    h2 {
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    &-text {
      font-size: 15px;
      font-weight: 300;
      color: var(--v-greyish-darken1);
    }

    &-fields {
      padding: 30px 0;
    }

    &-link {
      text-align: center;
      margin-top: 30px;
      font-size: 15px;

      a {
        text-decoration: none;
      }
    }
  }
  &-img {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 450px;
      width: 100%;
    }
  }
}
</style>
